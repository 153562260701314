<script>
// @ is an alias to /src
import axios from 'axios'
import { parse } from 'tinyduration'
import { stripKeyFromIdentifier, formatDuration } from '@/utils.js'
import InventoryPreview from '@/components/player/inventory/InventoryPreview.vue'
import ErrorBanner from '@/components/ErrorBanner.vue';
import Breadcrumbs from '../components/Breadcrumbs.vue'

export default {
  name: 'Graveyard',
  components: {
    InventoryPreview,
    ErrorBanner,
    Breadcrumbs
},
  data() {
    return {
      craftavatarURL: 'https://crafatar.com/renders/body/',
      loading: true,
      errors: [],
      player: [],
      data: {},
      currentTheme: this.$store.getters.darkMode,
    };
  },
  watch: {
    //reload the data with the correct colored fill ins - if this isn't performant, move it to a Compotent and force reload via Compotent Key - must reload somehow due to the color mode fill ins for undefined imgs
    '$store.getters.darkMode': function () {
      this.getUserData();
    },
  },
  created() {
    if (this.$route.params.name != null)
      this.getUserData();
    else {
      this.loading = false;
      this.errors = 'Not found!';
    }
  },
  methods: {
    updateTitle(name) {
      document.title = name + "'s Graveyard | Oracle";
    },
    getUserData() {
      axios
        .get(this.$store.getters.getPlayerEndpoint + this.$route.params.name)
        .then((response) => {
          this.player = response.data.player;

          this.data.player = response.data.data;
          this.data.player['rank'] = stripKeyFromIdentifier(this.data.player['rank']).capitalizeFirstLetter();
          this.data.player['playTime'] = formatDuration(parse(this.data.player['playTime']));


          if (this.data.player['currentServer'] !== null) {
            this.data.player['currentServer'] = stripKeyFromIdentifier(this.data.player['currentServer']).capitalizeFirstLetter()
          }

          let characters = this.data.player.characters.all;
          characters.map((char) => {
            char['playTime'] = formatDuration(parse(char['playTime']));
            char['type'] = stripKeyFromIdentifier(char['type'])
            return char
          });

          let character = characters.find(char => char.uniqueId == this.$route.params.character);
          if (character === undefined)
            throw new Error("no character found with provided UUID");

          this.data.characters = [];

          character = character.previousLife;
          while (character != null) {
            this.data.characters.push(character);
            character = character.data.previousLife;
          }

          this.loading = false;
          this.updateTitle(this.player.name);
        })
        .catch((e) => {
          this.errors.push(e);
          console.log(e);
          this.errors.isError = true;
          this.loading = false;
        });
    },
    formatDuration,
  },
};
</script>
<template>
  <div class="lookup flex-1">
    <div
      v-if="(errors.isError && !loading) || $route.params.name == undefined"
      class="flex h-full"
    >
      <ErrorBanner
        :error="'No profile found for ' + $route.params.name"
        description="We don't think they've joined yet!"
      />
    </div>
    <div
      v-if="!loading && !errors.isError"
      class="about"
    >
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-16 mx-auto flex flex-col">
          <div class="lg:w-5/7">
            <div class="h-full w-full overflow-hidden">
              <Breadcrumbs
                :player="player"
                :data="data.player"
              />
            </div>
            <div class="flex flex-col sm:flex-row mt-10">
              <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div
                  class="rounded-full inline-flex items-center justify-center"
                >
                  <img
                    v-if="!loading"
                    class="transform"
                    alt="player"
                    :src="craftavatarURL + player.id + '?overlay'"
                  >
                </div>
                <div
                  class="flex flex-col items-center text-center justify-center"
                >
                  <h2 class="font-medium title-font mt-4 dark:text-amber-600 text-indigo-800 text-lg">
                    {{ player.name }}
                  </h2>
                  <div class="w-12 h-1 dark:bg-amber-600 bg-indigo-800 rounded mt-2 mb-4" />

                  <div class="grid grid-cols-1 grid-rows-1 gap-4 py-2 content-evenly">
                    <div
                      v-if="data.player.rank !== undefined"
                      class="dark:text-white text-base mx-auto my-auto"
                    >
                      Rank — {{ data.player.rank }}
                    </div>
                  </div>
                  <div class="grid grid-cols-2 grid-rows-1 gap-4 py-2 content-evenly">
                    <div
                      v-if="data.player.balance !== undefined"
                      class="dark:text-white text-base mx-auto my-auto"
                    >
                      ❖{{
                        Number(data.player.balance).toLocaleString()
                      }}
                    </div>
                    <div class="has-tooltip text-base">
                      <img
                        class="mx-auto my-auto"
                        :src="`https://cdn.telosrealms.com/img/mastery/${Math.floor(Object.values(data.player.characters.data.mastery).reduce((acc, val) => acc += val, 0))}.png`"
                      >
                      <div class="dark:bg-gray-700 dark:text-gray-300 tooltip rounded shadow-lg p-2 m-2 bg-gray-100 -mt-20">
                        Mastery — {{ Math.floor(Object.values(data.player.characters.data.mastery).reduce((acc, val) => acc += val, 0)) }}
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-1 grid-rows-1 gap-4 py-2 content-evenly">
                    <div
                      class="dark:text-white text-base mx-auto my-auto"
                    >
                      Played for {{ this.data.player.playTime }}
                    </div>
                  </div>
                  <p
                    v-if="data.player.currentServer != null"
                    class="text-white bg-yellow-600 rounded-lg px-6 py-2 font-extrabold"
                  >
                    Currently playing on {{ data.player.currentServer }}
                  </p>
                </div>
              </div>
              <div
                class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-indigo-800 dark:border-amber-600 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left"
              >
                <div class="container px-5 mx-auto">
                  <div
                    class="flex flex-wrap -m-4 dark:bg-gray-900 dark:text-gray-200"
                  >
                    <table class="table-auto width:100%;">
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>Fame</th>
                          <th>Items</th>
                          <th>Cause</th>
                          <th>Timestamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="char in data.characters"
                          :key="char"
                        >
                          <!-- Level -->
                          <td>{{ char.data.level }}</td>

                          <td>{{ Number(char.data.fame).toLocaleString() }}</td>

                          <!-- Items -->
                          <td>
                            <InventoryPreview :inventory="char.data.inventory" />
                          </td>

                          <td>{{ char.cause }}</td>

                          <td>{{ new Date(char.timestamp).toLocaleString() }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style scoped>
table {
  width: 100%;
}
img {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
}
.itemcrop {
    width: 34px; /* width of container */
    height: 34px; /* height of container */
    object-fit: cover; /* object-fit property */
    object-position: 20% 0%; /* try 20px 10px */
}
</style>
